import { Text } from "@interco/inter-ui/components/Text";
import styled from "styled-components";

export const StyledHeader = styled.div`
  display: inline-block;
  flex-direction: column;
  align-items: flex-start;
  left: 0px;
  padding: 24px 10% 0px 10%;
  background: var(--white);
  box-shadow: 0px 10px 30px rgba(75, 78, 92, 0.1);
  width: 100%;
`;

export const StyledBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 769px) {
    flex-direction: row;
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 16px;
`;

export const StyledTab = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  > span {
    margin-top: 12px;
  }
`;

export const StyledPrincipalHeading = styled(Text)`
  margin-top: 4px;
  margin-left: 22px;
`;
