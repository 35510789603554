import { Text } from "@interco/inter-ui/components/Text";
import { Spacing } from "@interco/inter-ui/components/Spacing";

import * as S from "./styles";

const Footer = () => (
  <S.StyledFooter>
    <Text variant="headline-h3">Portal Imobiliário</Text>

    <Spacing mt="xs"></Spacing>
    <Text variant="caption-2">
      <p>
        Banco Inter S.A. - Avenida Barbacena, 1219 - Santo Agostinho. CEP:
        30190-924 - Belo Horizonte/MG
        <br />
        CNPJ: 00.416.968/0001-01
      </p>
    </Text>
  </S.StyledFooter>
);

export default Footer;
