import styled from "styled-components";

import { Footer } from "@interco/inter-ui/components/Footer";

export const StyledFooter = styled(Footer)`
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 275px;
  background: var(--white);

  padding: 24px 10% 24px 10%;
  width: 100% !important;
`;
