import { useNavigate } from "react-router-dom";

import * as S from "./styles";

import RoutesNames from "../../routes/RoutesNames";
import { useAuthentication } from "../../hooks/useAuthentication";
import { getInitialsFromName } from "../../utils/string";

type UserMenuType = {
  name?: string;
};

const UserMenu = ({ name = "" }: UserMenuType) => {
  const navigate = useNavigate();

  const { handleLogout } = useAuthentication();

  return (
    <S.StyledBox>
      <S.StyledCircle>{getInitialsFromName(name)}</S.StyledCircle>
      <S.StyledGreatings>
        <span>Olá!</span>
        <span>
          <strong>{name}</strong>
        </span>
      </S.StyledGreatings>
      <S.StyledLine />

      <S.StyledLinkButton
        variant="link"
        onClick={() => {
          handleLogout();
          navigate(RoutesNames.LOGIN);
        }}
      >
        Sair
      </S.StyledLinkButton>
    </S.StyledBox>
  );
};

export default UserMenu;
