import { useContext } from "react";
import { AuthContext } from "../../contexts/Authentication";

import AcompanhamentoPropostas from "@interco/credito-imobiliario-acomp-propostas";
import Container from "../../components/Container";

const Proposals = () => {
  const AuthData = useContext(AuthContext);
  const gatewaysUrls = { "ci-api": process.env.REACT_APP_API_URL };

  return (
    <Container>
      <AcompanhamentoPropostas
        partner={AuthData.userInfo}
        gatewaysUrls={gatewaysUrls}
        accessToken={AuthData.token}
      />
    </Container>
  );
};
export default Proposals;
