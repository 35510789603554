// Get`s the first char in first word and the first char in last word
// that are separated by space
export const getInitialsFromName = (name: string = "") => {
  if (name === "") {
    return "";
  }

  const splittedWords = name.split(" ");

  return `${splittedWords[0]?.[0]}${
    splittedWords[splittedWords.length - 1]?.[0]
  }`;
};
