import { Button } from "@interco/inter-ui/components/Button";
import styled from "styled-components";

export const StyledBox = styled.div`
  display: none;

  @media (min-width: 769px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const StyledCircle = styled.span`
  height: 40px;
  width: 40px;
  background-color: var(--secondary500);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 12px;
`;

export const StyledGreatings = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLine = styled.div`
  height: 36px;
  border-right: 1.5px dashed var(--gray200);
  margin: 0px 1.5rem 0px 1.5rem;
`;

export const StyledLinkButton = styled(Button)`
  color: var(--gray400) !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 0;

  :hover:enabled:active {
    background: transparent !important;
  }
`;
