import { useLayoutEffect } from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";

import Footer from "../components/Footer";
import Header from "../components/Header";
import RoutesNames from "./RoutesNames";
import { useAuthentication } from "../hooks/useAuthentication";

const MoveToTop = () => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <></>;
};

const PrivateRoute = () => {
  const curityResponse = new URLSearchParams(location.search);
  const { token, userInfo } = useAuthentication();

  if (!token && !curityResponse.has("code")) {
    return <Navigate to={RoutesNames.LOGIN} replace />;
  }

  return (
    <>
      <MoveToTop />
      <Header userInfo={userInfo} />
      <Outlet />
      <Footer />
    </>
  );
};

export default PrivateRoute;
