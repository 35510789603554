import { useState } from "react";
import Router from "./routes";
import { AuthenticationProvider } from "./contexts/Authentication";
import { CurrentTabContext } from "./contexts/CurrentTab";

const App = () => {
  const [currentTab, setCurrentTab] = useState<string | number>('')
  return (
    <CurrentTabContext.Provider value={[currentTab, setCurrentTab]}>
      <AuthenticationProvider>
        <Router />
      </AuthenticationProvider>
    </CurrentTabContext.Provider>
  )
};

export default App;
