import { Suspense } from "react";
import ReactDOM from "react-dom/client";

import GlobalStyles from "./globalStyles";
import App from "./App";

import "@interco/inter-ui/inter-ui.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <GlobalStyles />

    <Suspense fallback="Loading...">
      <App />
    </Suspense>
  </>
);
