import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  html,
  body,
  #root {
    height: 100vh;
    width: 100vw;
    margin: 0px;
    padding: 0px;
  }
`;

export default GlobalStyles;
