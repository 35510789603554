import OAuthAssistant from "@curity/oauth-assistant";
import jwtDecode from "jwt-decode";

export const oAuthScopes = ["openid", "CI-API"];

// const debug = process.env.ENVIRONMENT === "development";
const issuer = `${process.env.REACT_APP_CURITY_URL}/generic/oauth`;

const settings: OAuthAssistant.Settings = {
  flow_type: "code",
  base_url: `${process.env.REACT_APP_CURITY_URL}`,
  client_id: "ci-portal-parceiros-client",
  issuer,
  redirect_uri: `${process.env.REACT_APP_SITE_URL}`,
  for_origin: `${process.env.REACT_APP_CURITY_URL}`,
  allowed_origins: [window.origin],
  jwt_sig_public_key: {
    format: "issuer",
    value: null,
  },
  ignore_not_before: false,
  ignore_expiration: false,
  clock_tolerance: 0,
  debug: true,
  openid_configuration_url: `${issuer}/.well-known/openid-configuration`,
};

export const oAuthAssistant = new OAuthAssistant(settings);

export const isTokenExpired = () => {
  const tokenExpirationTime = +(
    sessionStorage.getItem("tokenTime") || 0
  );

  return tokenExpirationTime <= Date.now();
};
