import { BrowserRouter, Route, Routes } from "react-router-dom";

import RoutesNames from "./RoutesNames";
import PrivateRoute from "./PrivateRoute";
import Proposals from "../pages/Proposals";
import Simulator from "../pages/Simulator";

import Login from "../pages/Login";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={RoutesNames.LOGIN} element={<Login />} />

        <Route element={<PrivateRoute />}>
          <Route index element={<Simulator />} />

          <Route path={RoutesNames.PROPOSALS} element={<Proposals />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
