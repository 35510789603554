import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { getUserData } from "../services/user";
import { calculateTokenExpirationTime } from "../utils/date";
import {
  isTokenExpired,
  oAuthAssistant,
  oAuthScopes,
} from "../services/curity/config";

type AuthenticationContext = {
  token: string | undefined;
  userInfo: UserInfo | undefined;
  handleLogin: () => void;
  handleLogout: () => void;
};

type AuthenticationProviderProps = {
  children: ReactNode;
};

export function AuthenticationProvider({
  children,
}: AuthenticationProviderProps) {
  const curityResponse = new URLSearchParams(location.search);

  const { current: oAuthAssistantInitPromise } = useRef(
    oAuthAssistant?.init().catch((e) => {
      console.log("Erro no init do assistant", e);
    })
  );

  const [token, setToken] = useState(oAuthAssistant?.getToken() || undefined);
  const [userInfo, setUserInfo] = useState<UserInfo>(JSON.parse(sessionStorage.getItem('user') || '{}'));

  const handleLogin = useCallback(async () => {
    await oAuthAssistantInitPromise;

    await oAuthAssistant?.authorize({
      scope: oAuthScopes,
      ui_locales: "pt-BR",
    });

    token && await handleUserData(token)
    sessionStorage.setItem('tokenTime', (calculateTokenExpirationTime()).toString())
  }, []);

  const handleUserData = async (token: string) => {
    const { data: user } = await getUserData(token)

    setUserInfo(user as UserInfo)
    sessionStorage.setItem('user', JSON.stringify(user))
  }

  const handleLogout = useCallback(async () => {
    await oAuthAssistantInitPromise;

    setToken(undefined);
    sessionStorage.removeItem('user')
    sessionStorage.removeItem('tokenTime')
    await oAuthAssistant?.revoke();
  }, []);

  const handleInit = useCallback(async () => {
    await oAuthAssistantInitPromise;
    console.log("antes do getToken")
    const localToken = oAuthAssistant?.getToken();

    console.log("localToken", localToken)

    if (localToken) {
      setToken(localToken);
      await handleUserData(localToken)
    } else {
      handleLogin()
    }
  }, []);

  const handleRefreshInvalidToken = useCallback(async () => {
    await oAuthAssistantInitPromise;

    if (isTokenExpired()) {
      console.log("Token expired. Requiring a new one.");
      handleLogin();
    }
  }, []);

  useEffect(() => {
    const handleSetup = async () => {
    if (curityResponse.has("code")) {
      handleInit();
    }  else if (token && Object.keys(userInfo).length === 0) {
      await handleUserData(token)
    } else {
      console.log("Verifying if token is invalid", isTokenExpired());
      handleRefreshInvalidToken();
    }
  }

  handleSetup()
  }, []);

  const value = useMemo(
    () => ({
      userInfo,
      handleLogin,
      handleLogout,
      token,
      setToken,
    }),
    [userInfo, handleLogin, handleLogout, token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const AuthContext = createContext<AuthenticationContext>(
  {} as AuthenticationContext
);
