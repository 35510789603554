import PartnersSimulator from "@interco/credito-imobiliario-simulador-parceiros";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/Authentication";
import { CurrentTabContext } from "../../contexts/CurrentTab";
import { tabNames } from "../../utils/tabNames";
import Container from "../../components/Container";

const Simulator = () => {
  const AuthData = useContext(AuthContext);
  const navigate = useNavigate();
  const [, setCurrentTab] = useContext(CurrentTabContext);

  const handleNavigationToProposal = (url: string) => {
    navigate(url);
    setCurrentTab(tabNames.proposals);
  };

  return (
    <Container whiteBackground>
      <PartnersSimulator
        gatewaysUrls={{ "ci.api": process.env.REACT_APP_API_URL }}
        accessToken={AuthData.token}
        handleParentNavigation={handleNavigationToProposal}
        partner={AuthData.userInfo}
      />
    </Container>
  );
};

export default Simulator;
