import { useEffect } from "react";
import { useAuthentication } from "../../hooks/useAuthentication";

const Login = () => {
  const { handleLogin } = useAuthentication();

  useEffect(() => {
    handleLogin();
  }, []);

  return null;
};

export default Login;
