import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { TabDefinition, TabPager } from "@interco/inter-ui/components/TabPager";
import RoutesNames from "../../routes/RoutesNames";
import InterLogo from "../../assets/inter.png";

import SimuladorIcon from "@interco/icons/orangeds/MD/salary-portability";
import ProposalsIcon from "@interco/icons/orangeds/MD/receipt";
import { CurrentTabContext } from "../../contexts/CurrentTab"
import { tabNames } from "../../utils/tabNames";

import * as S from "./styles";
import UserMenu from "../UserMenu";

const iconProps = {
  height: 20,
  width: 20,
  color: "#FF7A00",
};

const tabRoutes = {
  [tabNames.simulator]: RoutesNames.ROOT,
  [tabNames.proposals]: RoutesNames.PROPOSALS,
};

const tabDefinitions: TabDefinition[] = [
  {
    tabId: tabNames.simulator,
    label: (
      <S.StyledTab>
        <SimuladorIcon {...iconProps} />
        <span>Simulador</span>
      </S.StyledTab>
    ),
    content: <></>,
  },
  {
    tabId: tabNames.proposals,
    label: (
      <S.StyledTab>
        <ProposalsIcon {...iconProps} />
        <span>Propostas</span>
      </S.StyledTab>
    ),
    content: <></>,
  },
];

const Header = ({ userInfo }: { userInfo?: UserInfo }) => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useContext(CurrentTabContext)

  const handleTabChange = (tabId: string | number) => {
    setCurrentTab(tabId);

    navigate(tabRoutes[tabId]);
  };

  return (
    <S.StyledHeader>
      <S.StyledBar>
        <S.StyledTitle>
          <img src={InterLogo} height="24px" width="66px" alt="" />
          <S.StyledPrincipalHeading variant="headline-h1">
            Portal Imobiliário
          </S.StyledPrincipalHeading>
        </S.StyledTitle>

        <UserMenu name={userInfo?.parceiroNome} />
      </S.StyledBar>

      <TabPager
        currentTabId={currentTab}
        onTabSelect={handleTabChange}
        tabDefinitions={tabDefinitions}
        tabPanelStyle={{
          display: "none",
        }}
        tabItemStyle={{
          color: 'var(--neutral500)'
        }}
      />
    </S.StyledHeader>
  );
};

export default Header;
