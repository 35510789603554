import { useContext } from "react";
import { AuthContext } from "../contexts/Authentication";

export function useAuthentication() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuthentication must be used within a AuthProvider");
  }

  return context;
}
